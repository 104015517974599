import { DefaultComponent, LoadableLibrary } from '@loadable/component';
import {
  processorsList,
  illustrationList,
  logoList,
  richIconList,
  IncrementSizes,
  Illustrations,
  Logos,
  Processors,
  RichIcons,
  iconList,
  Icons,
  Icon,
} from '@loveholidays/design-system';
import React, { Fragment, ReactElement, ReactNode } from 'react';
import { SxStyleProp } from 'theme-ui';

import { IconOutlet } from './IconOutlet';
import { ClassNameProps } from '@ComponentProps';
import { loadable } from '@Core/DontHydrateLoadableLib';
import { captureClientError } from '@Core/errors/errors';
import { ColorsPalette } from '@UX/themes/types';

export interface DynamicSvgProps {
  children: (module: DynamicSvgModule) => ReactNode;
}

interface DynamicSvgModule {
  viewBox: string;
  svg: string;
}

/**
 * Provide `size` xor `height`
 */
export type SizeOrHeight =
  | {
      size: keyof IncrementSizes | (keyof IncrementSizes)[];
      height?: never;
    }
  | {
      size?: never;
      height: keyof IncrementSizes | (keyof IncrementSizes)[];
    };

/**
 * Restrict type of `name` depending on the `type`
 */
export type AssetTypes =
  | {
      type: 'Icon';
      name: Icons;
    }
  | {
      type: 'Illustration';
      name: Illustrations;
    }
  | {
      type: 'Logo';
      name: Logos;
    }
  | {
      type: 'Processor';
      name: Processors;
    }
  | {
      type: 'RichIcon';
      name: RichIcons;
    }
  | {
      type?: never;
      name: Icons | Illustrations | Logos | Processors | RichIcons;
    };

export type DesignSystemAssetProps = {
  /**
   * The color of the asset. Use colour token here.
   */
  color?: keyof ColorsPalette;

  svgStyles?: SxStyleProp;
  svg?: LoadableLibrary<DefaultComponent<unknown>>;
  fallback?: ReactElement;
} & Pick<ClassNameProps, 'className'> &
  SizeOrHeight &
  AssetTypes;

export const getSafeAsset = ({ name, type }: AssetTypes) => {
  let safeAssetName = name;
  let safeAssetType = type;

  if (
    !type ||
    !['Icon', 'Illustration', 'Logo', 'Processor', 'RichIcon'].includes(type) ||
    (type === 'Icon' && !iconList.includes(name)) ||
    (type === 'Illustration' && !illustrationList.includes(name)) ||
    (type === 'Logo' && !logoList.includes(name)) ||
    (type === 'Processor' && !processorsList.includes(name)) ||
    (type === 'RichIcon' && !richIconList.includes(name))
  ) {
    captureClientError(new Error(`Missing design system asset for ${type} ${name}`), {
      type,
      name,
    });
    // Fallback to a heart everywhere to avoid causing layout issues
    safeAssetName = 'USP/Favourites';
    safeAssetType = 'Illustration';
  }

  return { safeAssetName, safeAssetType };
};

// Try to infer the type from the name when type is not provided
export const getTypeFromName = (name: string) => {
  if (iconList.includes(name as Icons)) {
    return 'Icon';
  } else if (illustrationList.includes(name as Illustrations)) {
    return 'Illustration';
  } else if (logoList.includes(name as Logos)) {
    return 'Logo';
  } else if (processorsList.includes(name as Processors)) {
    return 'Processor';
  } else if (richIconList.includes(name as RichIcons)) {
    return 'RichIcon';
  } else {
    return;
  }
};

/**
 * Component to render assets.
 */
export const DesignSystemAsset: React.FC<DesignSystemAssetProps> = ({
  name,
  size,
  color,
  className,
  svgStyles,
  type = getTypeFromName(name),
  height,
  svg,
  fallback,
}) => {
  const { safeAssetName, safeAssetType } = getSafeAsset({ type, name } as AssetTypes);

  if (safeAssetType === 'Icon') {
    return (
      <Icon
        name={safeAssetName as Icons}
        size={size ?? height}
        className={className}
      />
    );
  }

  const dynamicSvgProps = {
    className,
    wrapperStyles: {
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: size ?? height,
      width: size ?? undefined,
      minWidth: size ?? undefined,
      minHeight: size ?? height,
    },
    loadableOptions: {
      fallback,
    },
  };

  const DynamicSvg = (svg ??
    loadable.lib(
      () =>
        import(
          /* webpackInclude: /(Illustration|Processor|RichIcon|Logo)\/.*.svg$/ */
          `@DesignSystemAssets/${safeAssetType}/${safeAssetName}.svg`
        ),
      dynamicSvgProps,
    )) as React.FC<DynamicSvgProps>;

  return (
    <DynamicSvg>
      {({ viewBox, svg }) => (
        <Fragment>
          <IconOutlet
            name={safeAssetName}
            svg={svg}
          />

          <svg
            viewBox={viewBox}
            sx={{
              fill: color,
              height: size ?? height,
              width: size ?? undefined,
              ...svgStyles,
            }}
          >
            <use href={`#${safeAssetName}`} />
          </svg>
        </Fragment>
      )}
    </DynamicSvg>
  );
};
